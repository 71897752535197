import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Stack, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import './Slider.css';
import Paper from '@mui/material/Paper';

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}



export default function MultiActionAreaCard({data}) {
  
  
  //For mobile view
const [windowSize, setWindowSize] = React.useState(getWindowSize());
      
React.useEffect(() => {
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
}, []);

  return (
    <>
    { windowSize.innerWidth < 670 ? (<Card sx={{
      width: 400, height: "100%",
      display: "flex",
      flexDirection: "column"
    }} elevation={16}>
      {(data.d_id || !data.toptext) &&
        <>
          <CardActionArea>
            <CardMedia
              component="img"
              height="350"
              image={data.img}
              alt="image"
              style={{ objectFit: "cover" }} />
          </CardActionArea>
        </>}
      {!data.d_id && data.toptext &&
        <>
          <CardActionArea>
            <CardMedia
              component="img"
              height="350"
              image={data.img}
              alt="image"
              style={{ objectFit: "cover" }} />
            <Divider style={{ marginTop: "0px" }} />
            <CardContent style={{ padding: "10px" }}>
              <Typography gutterBottom variant="h4" component="div">
                {data.toptext}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {data.headertext}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {data.newtext}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing sx={{ mt: "auto" }}>
            <Button size="small" color="primary">
              Contact
            </Button>
          </CardActions>
        </>}
    </Card> ):(<Paper elevation={16}>
      <Box sx={{width:'600px'}}>
        <Stack direction='row'>
          <Box>
            <img style={{width:'320px',height:'360px',padding:'2px'}} src={data.img} alt=''/>
          </Box>
          <Box sx={{marginLeft:'5px',marginTop:'8px'}}>
          <Typography gutterBottom variant="h5" component="div">
                {data.toptext}
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {data.headertext}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {data.newtext}
              </Typography>
          </Box>
        </Stack>
      </Box>
    </Paper>)}
   
    
    </>
  );
}
import React from 'react';
import Promo from './Promo';
import Team from './Team';
import Work from './Work';
import Aboutsection from './Aboutsection';
import Header from './Header';
import Contactsection from './Contactsection';
import Reviews from './Reviews';



export default function Templatethree() {
  
  return (
    <>
    <Header template_call={false}/>
    <Promo/>
    <Aboutsection/>
    <Team />
    <Work />
    <Reviews />
    <Contactsection/>
    </>
  )
}

import "./App.css";
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navmui from "./Components/Navmui";
import Footermui from "./Components/Footermui";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Templatethree from "./Components/Templatethree";
import Themecontext from "./Components/Themecontext";
import BackToTop from "./Components/BackToTop";
import NotFound from "./Components/NotFound";
function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#5893df",
        contrastText: "#fff",
      },
      secondary: {
        main: "#2ec5d3",
        contrastText: "#fff",
      },
      background: {
        default: "#192231",
        paper: "#24344d",
        contrastText: "#fff",
      },
    },
  });

  const defaultTheme = createTheme({
    palette: {},
  });

  const [themevalue, setThemevalue] = useState(0);
  const changeTheme = () => {
    if (themevalue === 0) {
      setThemevalue(1);
    }
    if (themevalue === 1) {
      setThemevalue(0);
    }
  };

  return (
    <Router>
      <Themecontext.Provider
        value={{ status: themevalue, changestatus: changeTheme }}
      >
        <ThemeProvider theme={themevalue === 0 ? defaultTheme : darkTheme}>
          <CssBaseline />
          <Navmui />
          <Routes>
            <Route path="/" render element={<Templatethree />} />
            <Route path='*' element={<NotFound />}/>
          </Routes>
          <Footermui b_color={themevalue === 0 ? "#1976d2" : null} />
          <BackToTop />
        </ThemeProvider>
      </Themecontext.Provider>
    </Router>
  );
}

export default App;

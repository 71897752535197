import React from 'react'
import doctorimage from './Images/DrDaniel.webp'
import './Promo.css'

function Promo() {
  
  let about_info;
  
  
  return (
    <>
    <div className="w3-container" style={{padding:"128px 16px"}} id='ourmission'>
  <div className="w3-row-padding" style={{marginLeft:'5%'}}>
    <div className="w3-col m6">
      <h2 style={{textAlign:'center'}}>Our Mission.</h2>
      <h3 style={{fontFamily:'Montserrat'}}>
      Committed To Dental Excellence
      </h3>
      <p>
        {about_info?about_info:`When you visit our office, your smile is our top priority. 
        Our new team is dedicated to providing you with the personalized, gentle care that 
        you deserve. Part of our commitment to serving our patients includes providing 
        information that helps them to make more informed decisions about their oral health 
        needs. This website is a resource we hope you’ll find both useful and interesting.`}
      </p>
      <p><a href="#ourwork" className="w3-button"><i className="fa fa-th"></i> View Our Works</a></p>
    </div>
    <div className="w3-col m6" >
      <img title='Buildings' className="w3-image w3-round-large" src={doctorimage} alt="Buildings"/>
    </div>
  </div>
</div>
    </>
  )
}

export default Promo
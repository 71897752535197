import * as React from 'react';
import './Newnavbar.css'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

//For mobile menu drawer

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StarRateOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import GrainOutlinedIcon from '@mui/icons-material/GrainOutlined';
import { ListItemButton, SwipeableDrawer } from '@mui/material';
import logo from './Images/od_new_logo.webp'

//For theme context
import Themecontext from './Themecontext';
//For theme button
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Bookingform from './Bookingform/Bookingform';
//For tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
//Calendly import
import {InlineWidget} from 'react-calendly'
import { TextField } from '@mui/material';

const pages = ['Our Mission','Services','Doctors','Our Work','Testimonials','Request Appointment','Contact Us'];
const pageIcons = {'Our Mission': <AutoAwesomeOutlinedIcon style={{color:'white',marginRight:'20px'}}/>,
                    'Services':<StarRateOutlinedIcon style={{color:'white',marginRight:'20px'}}/>,
                    'Doctors':<HealthAndSafetyOutlinedIcon style={{color:'white',marginRight:'20px'}}/>,
                    'Our Work': <GrainOutlinedIcon style={{color:'white',marginRight:'20px'}}/>,
                    'Testimonials': <ChatOutlinedIcon style={{color:'white',marginRight:'20px'}}/>,
                    'Request Appointment': <AutoStoriesOutlinedIcon style={{color:'white',marginRight:'20px'}}/>,
                    'Contact Us': <ContactPhoneOutlinedIcon style={{color:'white',marginRight:'20px'}}/>
                  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  //For window height
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function Navmui() {
    // eslint-disable-next-line
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    
    // eslint-disable-next-line
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    
    let dentalname='Odex Dental';
     

    let refer=["#ourmission",'#about',"#team","#ourwork",'#testimonials','#booking','#contact']

    let avatarimg=logo;
    let avatarsize;
    let avatarvariant;
    
    let avatarwidth='70px';
    let avatarheight='70px';
    if(avatarsize!=null){
        if(avatarsize==='small'){
        avatarwidth='45px';
        avatarheight='45px';
        }
        if(avatarsize==='medium'){
        avatarwidth='55px';
        avatarheight='55px';
        }
        if(avatarsize==='large'){
        avatarwidth='80px';
        avatarheight='80px';
        }
    }


  //For booking dialog 

  const [openbooking, setOpenbooking] = React.useState(false);

  const handleClickOpenbooking = () => {
    setOpenbooking(true);
  };

  const handleClosebooking = () => {
    setOpenbooking(false);
  };

  //Menu Drawer
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box style={{display:'flex',flexDirection:'row', alignItems:'center', margin:'10px 0px'}}>
  
              <Avatar  style={{marginLeft:'10px',marginTop:"5px",marginBottom:"5px"}} src={avatarimg} 
              sx={{height:avatarheight,width:avatarwidth,backgroundColor:'white'}} variant={avatarvariant} >
              {dentalname.slice(0,1)}  
              </Avatar>
          <Box>
          <Typography
          variant="h6"
          component="a"
          href="#ourmission"
          sx={{
            mr: 2,
            ml: 1,
            display: { md: 'flex' },
            fontFamily: 'Raleway',
            fontWeight: 700,
            fontSize: '1.19rem',
            letterSpacing: '.3rem',
            color: 'white',
            lineHeight: '1',
            whiteSpace:'nowrap',
            textDecoration: 'none',
          }}
        >
          {dentalname?dentalname:'Your Business Name'}
        </Typography>
        <Typography
        sx={{fontSize:'0.75rem',textAlign:'center',color:'#ef6b67'}}>
          ...a caring family dental
        </Typography>
        </Box>
        </Box>
        
      <Divider />
      <List>
      {pages.map((page,index) => (
                  <div key={page}>
                  {page === 'Request Appointment' &&
                    <><ListItem
              key={page}
              onClick={() => { handleClickOpenbooking(); handleCloseNavMenu(); } }
            >
              {pageIcons[page]}
              <ListItemText style={{color:'white'}}>{page}</ListItemText>
            </ListItem><Divider style={{ borderColor: '#efe7e73b' }} /></>
                  }
                  {page !== 'Request Appointment' &&
                    <><ListItemButton
              key={page}
              onClick={() => { handleCloseNavMenu(); } }
              href={refer[index]}
            >
              {pageIcons[page]}
              <ListItemText style={{color:'white'}} >{page}</ListItemText>
            </ListItemButton><Divider style={{ borderColor: '#efe7e73b' }} /></>
                  }
                  </div>
                ))}
                <ListItemButton  onClick={valueTheme.changestatus} sx={{color:'white'}}>
                {valueTheme.status === 0 ? <Brightness7Icon style={{marginRight:'20px'}} /> : <Brightness4Icon style={{marginRight:'20px'}} />}
                <ListItemText>Change Theme</ListItemText> 
              </ListItemButton>
              <Divider style={{borderColor:'#efe7e73b'}}/>
        </List>
    </Box>
  );


  //Context For theme
  const valueTheme = React.useContext(Themecontext)

  //For tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [calendlylink, setCalendlylink] = React.useState('https://calendly.com/dawoodminhas155/dental-checkup');

  const handleLinkchange = (e) => {
    setCalendlylink(e.target.value)
    if(e.target.value===''){
      setCalendlylink('https://calendly.com/dawoodminhas155/dental-checkup')
    }
  }

  //For window resize
  const [windowSize, setWindowSize] = React.useState(getWindowSize());


  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    
        <AppBar position="sticky" color='primary'
        sx={{height:90,justifyContent:'center'}}>
          
      {/* For booking dialog */}
      <Dialog
        open={openbooking}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClosebooking}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{}</DialogTitle>
        <DialogContent>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Booking Form" />
              <Tab label="Calendly" />
              
            </Tabs>
          </Box>
          {
            value===0 && (
              <>
                <Bookingform />
              </>
            )
          }
          {
            value===1 &&(
              <>
               {windowSize.innerWidth <= 499 ? (<>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
               <TextField style={{width: "90%", alignSelf: 'center', marginTop: '40px'}} 
               id="outlined-basic" label="Enter your Calendly Link" variant="outlined" 
               defaultValue={''} onChange={(e)=> handleLinkchange(e)}
               />
                <InlineWidget 
                styles={{minWidth: 'unset', height: '680px'}}
                url={calendlylink}
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: false,
                  primaryColor: '00a2ff',
                  textColor: '4d5055'
                }}
                 />
              </div>
               </>):(
                <>
                {windowSize.innerWidth<=900?(<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
               <TextField style={{width: "60%", alignSelf: 'center', marginTop: '40px'}} 
               id="outlined-basic" label="Enter your Calendly Link" variant="outlined" 
               defaultValue={''} onChange={(e)=> handleLinkchange(e)}
               />
                <InlineWidget 
                styles={{minWidth:'1100px',height:'680px'}}
                url={calendlylink}
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: false,
                  primaryColor: '00a2ff',
                  textColor: '4d5055'
                }}
                 />
              </div>):(<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
               <TextField style={{width: "60%", alignSelf: 'center', marginTop: '40px'}} 
               id="outlined-basic" label="Enter your Calendly Link" variant="outlined" 
               defaultValue={''} onChange={(e)=> handleLinkchange(e)}
               />
                <InlineWidget 
                styles={{minWidth:'1100px',height:'680px'}}
                url={calendlylink}
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: '00a2ff',
                  textColor: '4d5055'
                }}
                 />
              </div>)}
                
              </>)}
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosebooking} variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
          <Box className='AvatarBox'>
              
                
                  <Avatar  style={{marginRight:'10px',marginTop:"5px",marginBottom:"5px",backgroundColor:'white'}} 
                  src={avatarimg} 
                  sx={{height:avatarheight,width:avatarwidth}} variant={avatarvariant} >
                  {dentalname.slice(0,1)}  
                  </Avatar>
                
              
              
            </Box>
            <Box>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#ourmission"
              className='navbartext'
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Raleway',
                fontWeight: 900,
                fontSize: 33,
                lineHeight: '1',
                letterSpacing: '.2rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {dentalname?dentalname:'Your Business Name'}
            </Typography>
            <Typography
            textAlign='center'
            sx={{
              color:'#ef6b67'
            }}
            >
              ...a caring family dental
            </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(anchor, true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
                PaperProps={valueTheme.status === 0 ? {
                  sx: {
                    backgroundColor: "#1976d2",
                  }
                }:{}}
              >
              {list(anchor)}
              </SwipeableDrawer>
            </Box>
            
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#ourmission"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Raleway',
                fontWeight: 400,
                fontSize: '5.3vw',
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {dentalname?dentalname:"Your Business Name"}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
              {pages.map((page,index) => (
                <div key={page}>
                {page === 'Request Appointment' &&
                <Button
                  key={page}
                  onClick={handleClickOpenbooking}
                  sx={{ my: 2, color: 'white', display: 'block',fontSize:'0.8rem' }}
                >
                  {page}
                </Button>}
              {page !=='Request Appointment'  &&
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block',fontSize:'0.8rem' }}
                  href={refer[index]}
                >
                {page}
                </Button>}
                </div>
              ))}
              <IconButton sx={{ fontSize:'1.1rem' }} onClick={valueTheme.changestatus} color="inherit">
                {valueTheme.status === 0 ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            
            </Box>
  
            
          </Toolbar>
        </Container>
      </AppBar>
      
  )
}

export default Navmui
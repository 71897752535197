import React, { useState} from 'react'
import './Contactus.css' 
import { Button } from '@mui/material'
// import { IconButton } from '@mui/material';
import { PhoneFill } from '@rsuite/icons'
// import { EmailSharp } from '@mui/icons-material'
import ReCAPTCHA from 'react-google-recaptcha'
import building from './Images/Contactusimage.webp'
import TextField from '@mui/material/TextField';
// import Reaptcha from 'reaptcha';
// import axios from 'axios'

const days = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday']
const hours = ['9:00 am - 6:00 pm',
                '8:00 am - 5:00 pm',
                '8:00 am - 5:00 pm',
                '9:00 am - 6:00 pm',
                'closed',
                'closed',
                'closed']

// eslint-disable-next-line             
const address='26300 Euclid Ave 9th floor, Euclid, OH 44132, United States'


function Contactsection() {

    const [verfied,setVerified] = useState(false)
    const onChangeCaptcha = (value) => {
        console.log("Captcha Value: ", value)
        setVerified(true)
      }

  
 return (
    <>
    <div className='w3-border-bottom w3-border-grey' id="contact" style={{padding:"40px 16px",opacity:"20%"}}></div>
    <h3 className="w3-center" >Contact Us</h3>
    <div className='w3-border-bottom w3-border-gray' style={{padding:"0px 16px",opacity:"20%"}}></div>
    <div className='map-section' >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2984.171440919813!2d-81.49965797970769!3d41.58717373490028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88310014c9ca70f1%3A0x3a8f2f06dbc3e450!2sOdex%20Dental!5e0!3m2!1sen!2s!4v1673898428367!5m2!1sen!2s"
             className='iframe' title='maps' >
        </iframe>
        <div className='opening-hours'>
            <h6>Opening hours</h6>
            {days.map((day,index)=>(
                <div key={day} className='label-section'>
                <label className='labels'>
                    {day}
                    <span className='span-items'>
                        {hours[index]}
                    </span>
                </label>
                </div>
            )
            )}
        </div>
        <div className='getintouch'>
            <h6>Get in touch</h6>
            <Button href="tel:+1 (216) 797-1401" variant='outlined'>
                <PhoneFill  style={{marginRight:'5px'}}/> <span style={{whiteSpace: "nowrap"}}>+1 (216) 797-1401</span>
            </Button>
            <div className='location-address'>
                <label>26300 Euclid Ave 9th floor,<br/> Euclid, OH 44132, <br/>United States</label>
            </div>
            {/* <label id='contact-email' style={{display:'flex',alignItems:'center'}}>
                <IconButton sx={{padding:'unset'}} href='mailto:progressivefield@hotmail.com'>
                    <EmailSharp style={{marginRight:'5px'}} />
                </IconButton>
                <a href='mailto:progressivefield@hotmail.com' style={{color:"#1976d2",textDecoration:'none'}}>
                progressivefield@hotmail.com
                </a>
            </label> */}
        </div>

    </div>
    <div className="w3-container w3-padding-32" >
          <h3 className="w3-border-bottom w3-border-grey w3-padding-16" style={{opacity:"20%"}}> </h3>
          <p>Send us a message.</p>
          <div className='contact-section'>
              <div className='contact-form' style={{ width: "95%", marginRight: "2%" }}>
                  <form>
                  <TextField
                        id="outlined-multiline-static"
                        required
                        placeholder='Name'
                        InputLabelProps={{ shrink: false }}
                        rows={1}
                        fullWidth
                        style={{margin:'8px 0px'}}/>
                  <TextField
                        id="outlined-multiline-static"
                        required
                        placeholder='Email'
                        InputLabelProps={{ shrink: false }}
                        rows={1}
                        fullWidth
                        style={{margin:'8px 0px'}}/>
                  <TextField
                        id="outlined-multiline-static"
                        required
                        placeholder='Subject'
                        InputLabelProps={{ shrink: false }}
                        rows={1}
                        fullWidth
                        style={{margin:'8px 0px'}}/>
                  <TextField
                        id="outlined-multiline-static"
                        required
                        multiline
                        rows={2}
                        placeholder='Comment'
                        InputLabelProps={{ shrink: false }}
                        fullWidth
                        style={{margin:'8px 0px'}}/>
                      <ReCAPTCHA  sitekey={process.env.REACT_APP_SITE_KEY} onChange={onChangeCaptcha}/>
                      <button  disabled={!verfied} className="w3-button w3-section" type="submit" style={{ backgroundColor: "rgb(25, 118, 210)", color: "white", borderRadius: "7px" }}>
                          <i className="fa fa-paper-plane"></i> SEND MESSAGE
                      </button>
                  </form>
              </div>
              <img src={building}
              alt='' style={{height:'300px',maxWidth:'500px',borderRadius:'2px',marginRight:'1%'}}/>
          </div>
      </div>
      </>
  )
}

export default Contactsection

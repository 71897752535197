import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { IconButton } from '@mui/material';
import {
  FaFacebook,
  FaInstagram,
  // FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import './Footermui.css'
import { TbDental } from "react-icons/tb";
import {Link} from "react-router-dom";

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function Footermui({b_color}) {
  
    const [windowSize, setWindowSize] = React.useState(getWindowSize());
  
  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);


  let dentalname='Odex Dental';
  let address='26300 Euclid Ave 9th floor, Euclid, OH 44132, United States';
  
  
    return (
    <>
    {windowSize.innerWidth>887? (<Paper elevation={24} sx={{borderRadius:'0px',backgroundColor:b_color}}  >
    <Box
      sx={{display:'flex',alignItems:'center',flexDirection:'column',padding:'30px 0px 15px 0px', gap:'20px'}}
      
    >
      <Box>
        <Typography fontSize='20px' color='white'>
        {address?address:'Washington DC Metropolitan Area, USA'}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', gap: '120px',alignItems:'center'}}>
        <Box sx={{display:'flex',alignItems:'center'}} >
        <TbDental style={{fontSize:'2rem',color:'white'}} />
        <Typography fontSize='30px' color='white'>
        {dentalname?dentalname:'Your Business name'}
        </Typography>
        </Box>
        <Box>
          <Link target="blank" style={{textDecoration: "none"}} to='https://firststopdental.com/'>
          <Typography fontSize='12px' color='white'>
         Powered by First Stop Dental © 2023
          </Typography>
          </Link>
        </Box>
        <div className='social-icons'>
            <IconButton
              href='//www.facebook.com/profile.php?id=100079145980573'
              className='social-icons-link-three facebook'>
                <FaFacebook />
            </IconButton>
            <IconButton
              href='//www.instagram.com/odexdental/'
              className='social-icons-link-three instagram'>
                <FaInstagram />
            </IconButton>
            {/* <IconButton
              href='/'
              className='social-icons-link-three youtube'>
                <FaYoutube />
            </IconButton> */}
            <IconButton
              href='/'
              className='social-icons-link-three twitter'>
                <FaTwitter />
            </IconButton>
            <IconButton
              href='/'
              className='social-icons-link-three linkedin'>
                <FaLinkedin />
            </IconButton>
          </div>
      </Box>
    </Box>
    </Paper>):(
      <Paper elevation={24} sx={{borderRadius:'0px',backgroundColor:b_color}}>
      <Box
        sx={{display:'flex',alignItems:'center',flexDirection:'column',padding:'30px 0px 15px 0px', gap:'20px'}}
      >
        <Box sx={{display:'flex',alignItems:'center'}} >
        <TbDental style={{fontSize:'2rem',color:'white'}} />
          <Typography fontSize='30px' color='white'>
          {dentalname?dentalname:'Your Business name'}
          </Typography>
          </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '15px',alignItems:'center'}}>
        <Box sx={{padding:'0px 4px'}}>
          <Typography fontSize='20px' color='white' textAlign='center'>
          {address?address:'Washington DC Metropolitan Area, USA'}
          </Typography>
        </Box>
          <div className='social-icons'>
              <IconButton
              href='//www.facebook.com/profile.php?id=100079145980573'
              className='social-icons-link-three facebook'>
                <FaFacebook />
            </IconButton>
            <IconButton
              href='//www.instagram.com/odexdental/'
              className='social-icons-link-three instagram'>
                <FaInstagram />
            </IconButton>
            {/* <IconButton
              href='/'
              className='social-icons-link-three youtube'>
                <FaYoutube />
            </IconButton> */}
            <IconButton
              href='/'
              className='social-icons-link-three twitter'>
                <FaTwitter />
            </IconButton>
            <IconButton
              href='/'
              className='social-icons-link-three linkedin'>
                <FaLinkedin />
            </IconButton>
            </div>
        </Box>
      </Box>
      </Paper>
    )}
    </>
  )
}

export default Footermui
import React from 'react'
import './Aboutsection.css'
import Card from '@mui/material/Card';
// import styled from 'styled-components';
import CardContent from '@mui/material/CardContent';

function Aboutsection() {
  return (
    <>
    <div className="w3-container" style={{padding:"20px 16px"}} id="about">
    <div className='w3-border-bottom w3-border-gray' style={{padding:"40px 16px",opacity:"20%"}}></div>
  <h3 className="w3-center">OUR SERVICES</h3>
  <div className='w3-border-bottom w3-border-gray' style={{padding:"0px 16px",opacity:"20%"}}></div>
  <p className="w3-center w3-large">Whether your dental needs are a complete exam and cleaning, a full-mouth restoration, or anything in between, we promise to provide you with exceptional care as we enhance the natural beauty of your smile. Below are just some of the many procedures and services we regularly provide to our patients – with a gentle touch, and stunning results. Your smile is our first priority, and we’ll give you something to smile about.</p>
  <div className="w3-row-padding w3-center" style={{marginTop:"64px"}}>
    <div className="w3-quarter" style={{margin:'8px 0px'}}>
    <Card elevation={6}  className="cards">
    <CardContent>
      <img src='https://cdn-icons-png.flaticon.com/512/4100/4100266.png'
      style={{width:"80px",height:"80px"}} alt='Services Card Logo'/>
      <p className="w3-large">Cosmetic Dentistry</p>
      <p>We all realize that having a healthy, bright, beautiful smile enhances our appearance and allows us to smile with confidence.</p>
      </CardContent>
    </Card>
    </div>
    <div className="w3-quarter" style={{margin:'8px 0px'}}>
    <Card elevation={6}  className="cards">
    <CardContent>
    <img src='https://cdn-icons-png.flaticon.com/512/3206/3206502.png'
      style={{width:"100px",height:"80px"}} alt='Services Card Logo'/>
      <p className="w3-large">Dental Implants</p>
      <p>Dental implants are a great way to replace missing teeth and also provide a fixed solution to having removable partial or complete dentures.</p>
    </CardContent>
    </Card>
    </div>
    <div className="w3-quarter" style={{margin:'8px 0px'}}>
    <Card elevation={6} className="cards">
    <CardContent>
    <img src='https://cdn-icons-png.flaticon.com/512/4100/4100872.png'
      style={{width:"80px",height:"80px"}} alt='Service Card Logo'/>
      <p className="w3-large">Dental X-Rays</p>
      <p>Dental radiographs are essential, preventative, diagnostic tools that provide valuable information not visible during a regular dental exam.</p>
    </CardContent>
    </Card>
    </div>
    <div className="w3-quarter" style={{margin:'8px 0px'}}>
    <Card elevation={6} className="cards" >
    <CardContent>
    <img src='https://cdn-icons-png.flaticon.com/512/2035/2035006.png'
      style={{width:"80px",height:"80px"}} alt='Services Card Logo'/>
      <p className="w3-large">Teeth Whitening</p>
      <p>Teeth whitening is a simple, non-invasive treatment used to change the color of natural tooth enamel. An ideal way to enhance your smile.</p>
    </CardContent>
    </Card>
    </div>
  </div>
</div>
    </>
  )
}

export default Aboutsection

// const CARD=styled.section`
// max-height:18.6rem;
// @media (max-width: 1060px) {
//   width:22rem;
// }
// `;
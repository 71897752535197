import React from 'react'
import './Reviews.css'
import Carousel from 'react-multi-carousel';
import Cardtestimonial from './Cardtestimonial';
import steph from './Images/Steph.webp'
import angel from './Images/Angel.webp'
import albina from './Images/Albina.webp'
import tishema from "./Images/avatar_hat.webp"
import ken_jones from "./Images/avatar.webp"
import eke_urum from "./Images/avatar1.webp"
import urenna from "./Images/avatar4.webp"
import jean from "./Images/avatar2.webp"
import ajah from "./Images/avatar3.webp"
function Reviews() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
       
      const images = [
        {img: steph,
        toptext:'Stephanie R',
        newtext:'Thank you so much for working so hard during the time I was in Cleveland to enable me to get my two crowns. I know this kind of procedure takes longer than a week, and I appreciate you making it happen! As always, each of you was so thoughtful, skilful, attentive and thorough. This is why I continue to proudly come back to you in Cleveland each time. I know that I am well taken care of.'
        },
        {img: angel,
        toptext:'Angelita B',
        newtext:'I love this office because they really live up to their name. The service is excellent and there is not a long wait. They treat my girls and I like we are a part of their family. It did not hurt to get fillings and cleanings.'
        },
        {
        img: albina,        
        toptext:'Albina K',
        newtext:'The workers are caring and loving. Dr Odeghe did a great job with my teeth. It’s a friendly environment.'
        },
        {
          img: tishema,
          toptext:'Tishema',
          newtext:'So I had a dental emergency and I was scheduled very quickly and had my tooth removed the very same day! If I could give you guys 100 stars I would, Dr. O made me feel very comfortable and safe and handled my procedure with care! I would recommend to anyone, as I will also be a new patient of Odex Dental from now on. Thanks so much everyone was so awesome! Keep up the great work!'
        },
        {
          img: ken_jones,
          toptext:'Ken Jones',
          newtext:'Dr. Daniel was very thorough, very patient and ensured that my level of comfort was primary. Dr. Daniel is very professional, and his staff is 1st class. Dr, Daniel provided me with a dental plan for my future visits to suit my needs now & in the future. I highly recommend his services'
        },
        {
          img: eke_urum,
          toptext:'Eke Urum',
          newtext:'I had to do a whitening and also get a consultation  to get braces. Dr. O was super professional, helpful and was able to give me options that fit into my affordable insurance. Amazing service!'
        },
        {
          img: urenna,
          toptext:'Urenna Kalu-Onuma',
          newtext:'Dr Odeghe did a fantastic job with everything! I really enjoyed my visit and most importantly, he made sure I was comfortable and understood the procedures planned.'
        },
        {
          img: jean,
          toptext:'Jean T',
          newtext:'They are very professional and were able to fit me in after my crown came out! I appreciate that!! We are working on a plan for me with my insurance company.'
        },
        {
          img: ajah,
          toptext:'Ajah Emmanuel',
          newtext:'Superb experience. World-class, industry best practices. Cutting edge . I got my teeth glossy shiny and healthy'
        }
        
    ]

    return (
    <div style={{padding:'80px 0px'}} id='testimonials'>
    <div className='w3-border-bottom w3-border-gray' style={{opacity:"20%"}}></div>
    <h3 className="w3-padding-16"  >Testimonials</h3>
    <div className='w3-border-bottom w3-border-gray' style={{opacity:"20%"}}></div>
    <Carousel 
    swipeable={true}
    draggable={false}
    showDots={true}
    responsive={responsive}
    ssr={true} // means to render carousel on server-side.
    infinite={true}
    autoPlay={true}
    autoPlaySpeed={1500}
    keyBoardControl={true}
    customTransition="all .5"
    transitionDuration={2000}
    containerClass="carousel-container"
    // removeArrowOnDeviceType={["tablet", "mobile"]}
    deviceType={["tablet", "mobile"]}
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px"
    >
    {
    images.map(data=>(
        <div key={data}>
        <Cardtestimonial data={data}/>
        </div>
    ))
    }
    </Carousel>  
    </div>
  )
}

export default Reviews
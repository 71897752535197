import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, CardActionArea } from '@mui/material';
import { Box } from '@mui/system';
import './Testimonials.css'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

function Cardtestimonial({data}) {
  return (
    

    <Card sx={{ maxWidth: '70%', maxHeight:'unset', margin:'auto' }}  elevation={24}>
      <CardActionArea>
        <CardContent>
          <FormatQuoteIcon style={{transform:'rotate(180deg)',fontSize:'1.8rem'}}/>
          <Typography variant="body1" color="text.secondary" padding='0px 25px'>
            {data.newtext? data.newtext : `Dr McIntyre has the best chair side manner of any dentist I have ever seen. 
            All the assistants are friendly and there to help you every step of the way. 
            I have always been treated with care and everyone is very professional. 
            This makes coming to the dentist a lot more pleasant. 
            My front teeth had lots of problems and the veneers solved all of them. 
            My smile looks better than it ever has. I’m always smiling, now that I have something 
            to smile about! Keep up the great work.`}
          </Typography>
          <FormatQuoteIcon  style={{right:'0',position:'absolute',margin:'0px 10px',fontSize:'1.8rem'}}/>
        </CardContent>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
        {data.img?(<Avatar src={data.img} style={{margin:"10px"}} sx={{height:'80px',width:'80px'}}/>)
        :(
          <Avatar style={{margin:"10px"}} sx={{height:'45px',width:'45px'}}>{data.toptext.slice(0,1)} </Avatar>
        )}
        <Typography gutterBottom variant="h5" style={{marginRight:'20px',marginBottom:'unset'}} >
            {data.toptext ? data.toptext : `- Jake Hunter`}
        </Typography>
        
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default Cardtestimonial
import React from 'react'
import image1 from './Images/Galleryimage1.webp'
import image2 from './Images/Galleryimage2.webp'
import image3 from  './Images/Galleryimage3.webp'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import b1 from './Images/b1.webp'
import b11 from './Images/b13.webp'
import b2 from './Images/b2.webp'
import b22 from './Images/b22.webp'
import b3 from './Images/b3.webp'
import b33 from './Images/b33.webp'
import b4 from './Images/b4.webp'
import b44 from './Images/b44.webp'
import b5 from './Images/b5.webp'
import b55 from './Images/b55.webp'
import b6 from './Images/b6.webp'
import b66 from './Images/b66.webp'
// import mov1 from './Images/mov1.mp4'
// import mov2 from './Images/mov2.mp4'
// import mov3 from './Images/mov3.mp4'
import Typography from '@mui/material/Typography';



function Work() {
  
  return (
    <>
    
  <div className="w3-container" style={{padding:"90px 16px"}} id="ourwork">
  <div className='w3-border-bottom w3-border-grey' style={{opacity:"20%"}}></div>
  <h3 className="w3-center">OUR WORK</h3>
  <div className='w3-border-bottom w3-border-grey'  style={{opacity:"20%"}}></div>
  <div className="w3-row-padding" style={{marginTop:"64px"}}>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
      <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <CardMedia component="img"
      height='250px'
      image={image3} title="Dr. Odeghe Daniel" >
      </CardMedia>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <CardMedia component="img"
      height='250px'
      image={image1} title="Dr. Odeghe Daniel">
      </CardMedia>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <CardMedia component="img"
      height='250px'
      image={image2} title="Dr. Odeghe Daniel" >
      </CardMedia>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <CardMedia component="img"
      height='250px'
      image={"https://images.unsplash.com/photo-1588776814546-1ffcf47267a5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"} >
      </CardMedia>
      </Card>
    </div>
  </div>

  <div className="w3-row-padding w3-section">
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <img src={b1} title="before"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
         <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>Before</Typography>
        </div>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">      
        <img src={b11} title="after"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
         <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>After</Typography>
        </div>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6} 
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>Before</Typography>
        </div>
        <img src={b2} title="before" 
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>After</Typography>
        </div>
        <img src={b22} title="after"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
  </div>

  <div className="w3-row-padding w3-section">
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>Before</Typography>
        </div>
        <img src={b3} title="before" 
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>After</Typography>
        </div>
        <img src={b33} title="after"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>Before</Typography>
        </div>
        <img src={b4} title="before"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>After</Typography>
        </div>
        <img src={b44} title="after"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
  </div>

  <div className="w3-row-padding w3-section">
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>Before</Typography>
        </div>
        <img src={b5} title="before"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>After</Typography>
        </div>
        <img src={b55} title="after"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>Before</Typography>
        </div>
        <img src={b6} title="before"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px"}}>
      <div className="w3-display-container">
        <div className="w3-display-topleft w3-padding" style={{backgroundColor:'rgb(25 118 210 / 59%)',borderBottomRightRadius:'4px'}}>
          <Typography sx={{color:'white'}}>After</Typography>
        </div>
        <img src={b66} title="after"
        alt="Our Work" style={{width:"100%",height: "250px",objectFit: "cover"}}/>
      </div>
      </Card>
    </div>
  </div>
</div>
{/* <div className="w3-container" style={{padding:"90px 16px"}}>
  <div className='w3-border-bottom w3-border-light-grey'></div>
  <h3 className="w3-center">VIDEO GALLERY</h3>
  <div className='w3-border-bottom w3-border-light-grey'></div>
  <div className="w3-row-padding" style={{marginTop:"64px",justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap'}}>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
      
      <Card elevation={6}
      sx={{width:"100%",height:"250px",display:'flex',justifyContent:'center'}}>
      <video controls style={{width:'250px',height:'250px'}}>
      <source src={mov1} type="video/mp4"/>
      </video>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px",display:'flex',justifyContent:'center'}}>
      <video controls style={{width:'250px',height:'250px'}}>
      <source src={mov2} type="video/mp4"/>
      </video>
      </Card>
    </div>
    <div className="w3-col l3 m6" style={{margin:'8px 0px'}}>
    <Card elevation={6}
      sx={{width:"100%",height:"250px",display:'flex',justifyContent:'center'}}>
      <video controls style={{width:'250px',height:'250px'}}>
      <source src={mov3} type="video/mp4"/>
      </video>
      </Card>
    </div>
  </div>
</div> */}
    </>
  )
}

export default Work



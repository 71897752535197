import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, InputAdornment, Stack, Typography } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import ReCAPTCHA from 'react-google-recaptcha';
import 'react-calendar/dist/Calendar.css';
import './Bookingform.css'
import { BiUserCircle } from "react-icons/bi";
import { MdAlternateEmail,MdOutlineDescription } from "react-icons/md";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './Formstyle.css'
import emailjs from '@emailjs/browser';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Modal from '@mui/material/Modal';



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


function Bookingform() {
  
  const [phone, setPhone] = useState('')

  const handleChange = (newPhone) => {
    setPhone(newPhone)
  }

  //For calendar in form
  const [valuetime, setValue] = useState(dayjs());  

  //For mobile view
  const [windowSize, setWindowSize] = useState(getWindowSize());
      
      useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);


  //Variables for form
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [description, setDescription] = useState('')

  const [firstnameerror, setFirstnameerror] = useState(false)
  const [lastnameerror, setLastnameerror] = useState(false)
  const [emailerror, setEmailerror] = useState(false)
  const [descriptionerror, setDescriptionerror] = useState(false) 
  const [phoneerror, setPhoneerror] = useState(false) 
  const [formerror, setFormerror] = useState(true)

  

  //Validation for variables
  const validateinputs=(e)=>{
    if(matchIsValidTel(phone)){
      setPhoneerror(false)
      setFormerror(false)
    }
    else{
      setPhoneerror(true)
      setFormerror(true)
    }
    if (firstname===''){
      setFirstnameerror(true)
      setFormerror(true)
    }
    else{
      setFirstnameerror(false)
      setFormerror(false)
    }
    if(lastname===''){
      setLastnameerror(true)
      setFormerror(true)
    }
    else{
      setLastnameerror(false)
      setFormerror(false)
    }
    if(!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
      setEmailerror(true)
      setFormerror(true)
    }
    else{
      setEmailerror(false)
      setFormerror(false)
    }
    if(description===''){
      setDescriptionerror(true)
      setFormerror(true)
    }
    else{
      setDescriptionerror(false)
      setFormerror(false)
    }
    
    submitemail();
      
  }



  function submitemail() {
    if(formerror===true){
      console.log('There is an error in the form')
    }
    else{
      console.log('Form is valid')
      console.log('Using Email js');
    emailjs.send("service_9n1jfjx", "template_ildnxrg", {
      from_name: `${firstname} ${lastname}`,
      to_name: "Odex Dental",
      message: `First Name: ${firstname}, Last Name: ${lastname}\n 
                            ${valuetime.toDate()}\n
                              Contact Number: ${phone} \n
                              Email: ${email} \n
                              Description: ${description}`,
    },'molfAR43dBTLquusx')
    .then(function(response) {
      console.log('SUCCESS!', response.status, response.text);
      setOpen(true)
      

   }, function(error) {
      console.log('FAILED...', error);
      setOpenfailure(true);
   });
    }
    
  }
  
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [openfailure, setOpenfailure] = React.useState(false);
  const handleClosefailure = () => setOpenfailure(false);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

  return (
    <>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
    <Box sx={style}>
    <Alert severity="success">
      <AlertTitle>Success</AlertTitle>
       <strong>We have recieved your request!</strong>
    </Alert>
    </Box>
    </Modal>
    <Modal
        open={openfailure}
        onClose={handleClosefailure}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
    <Box sx={style}>
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      The server may be down — <strong>try again after sometime!</strong>
    </Alert>
    </Box>
    </Modal>
    { windowSize.innerWidth < 500 ? (<>
      
      <Box sx={{ height: 255, maxWidth: 600, width: '100%', p: 2 }}>
          <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <div className='form-app'>
        <div className='form-section'>
      <Typography variant='h6' textAlign={'center'} marginTop='20px' marginBottom='10px' >
        Request Appointment
      </Typography>
      <Stack>
      <div style={{display:'flex'}}>
      <MuiTelInput value={phone} 
          onChange={handleChange} 
          defaultCountry='US' 
          label='Contact Number' 
          fullWidth 
          error={phoneerror}
          helperText={phoneerror?'Invalid phone number':null}
      />
      </div>
      <div >
        <Stack direction='column'  >
        <div style={{display:'flex'}}>
        <TextField
          id="outlined-multiline-flexible"
          label="First Name"
          placeholder = 'Enter your First Name'
          InputLabelProps={{ shrink: true }}
          multiline
          maxRows={4}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BiUserCircle style={{marginLeft:'5px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={(e)=>setFirstname(e.target.value)}
          defaultValue={firstname}
          error={firstnameerror}
          helperText={firstnameerror?'First Name cannot be empty':null}
        />
        </div>
        <div style={{display:'flex'}}>
        <TextField
          id="outlined-textarea"
          label="Last Name"
          placeholder="Enter your Last Name"
          InputLabelProps={{ shrink: true }}
          multiline
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BiUserCircle style={{marginLeft:'5px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={(e)=>setLastname(e.target.value)}
          defaultValue={lastname}
          error={lastnameerror}
          helperText={lastnameerror?'Last Name cannot be empty':null}
        />
        </div>
        </Stack>
        </div>
        <div style={{display:'flex'}}>
        <TextField
          id="outlined-textarea"
          label="Email"
          placeholder="Enter your Email"
          type = "email"
          InputLabelProps={{ shrink: true }}
          multiline
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdAlternateEmail style={{marginLeft:'5px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={(e)=>setEmail(e.target.value)}
          defaultValue={email}
          error={emailerror}
          helperText={emailerror?'Invalid Email':null}
        />
        </div>
        <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
            <DateTimePicker
              label="Date and Time"
              renderInput={(params) => <TextField {...params} />}
              value={valuetime}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
            </Stack>
            </LocalizationProvider>
        </div>
        <div style={{display:'flex'}}>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          placeholder='Describe salient aspects related to your appointment'
          InputLabelProps={{ shrink: true }}
          multiline
          rows={4}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdOutlineDescription style={{marginLeft:'5px',marginBottom:'25px'}}/>
              </InputAdornment>
            ),
          }}
          defaultValue={description}
          onChange={(e)=>setDescription(e.target.value)}
          error={descriptionerror}
          helperText={descriptionerror?'Description cannot be empty':null}
        />
      
      </div>
      <div style={{display:'flex',marginLeft:'9px'}}>
          <Button startIcon={<AttachFileIcon/>}
          component="label" variant='outlined' style={{color:'rgba(0, 0, 0, 0.57)',border:'1px solid rgba(0, 0, 0, 0.24)'}}>
          <input hidden accept="image/*" multiple type="file" />
          Attach Media
          </Button>
      </div>
      </Stack>
      
      <div>
      <Button variant="contained" style={{margin:'20px',width: "150px",height: "40px"}}
      onClick={(e)=>validateinputs(e)}>
        <Typography fontSize='15px'>Submit</Typography>
      </Button>
      </div>
    </div>
    </div>
    </Box>
    </Box>
    </>):(<>
          
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
        display:'flex',
        justifyContent:'center',
        flexWrap:'wrap-reverse'
      }}
      noValidate
      autoComplete="off"
    >
      <div className='form-app'>
        <div className='form-section'>
      <Typography variant='h6' textAlign={'center'} marginTop='20px' marginBottom='10px'  >
        Request Appointment
      </Typography>
      <Stack>
      <div>
      <MuiTelInput value={phone} 
          onChange={handleChange} 
          defaultCountry='US' 
          label='Contact Number' 
          fullWidth 
          style={{paddingRight:'15px'}}
          error={phoneerror}
          helperText={phoneerror?'Invalid phone number':null}
      />
      </div>
      <div>
        <Stack direction='row'>
        <TextField
          id="outlined-multiline-flexible"
          label="First Name"
          placeholder = 'Enter your First Name'
          InputLabelProps={{ shrink: true }}
          multiline
          maxRows={4}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BiUserCircle style={{marginLeft:'5px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={(e)=>setFirstname(e.target.value)}
          defaultValue={firstname}
          error={firstnameerror}
          helperText={firstnameerror?'First Name cannot be empty':null}
        />
        <TextField
          id="outlined-textarea"
          label="Last Name"
          placeholder="Enter your Last Name"
          InputLabelProps={{ shrink: true }}
          multiline
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BiUserCircle style={{marginLeft:'5px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={(e)=>setLastname(e.target.value)}
          defaultValue={lastname}
          error={lastnameerror}
          helperText={lastnameerror?'Last Name cannot be empty':null}
        />
        </Stack>
        </div>
        <div>
        <TextField
          id="outlined-textarea"
          label="Email"
          placeholder="Enter your Email"
          type = "email"
          InputLabelProps={{ shrink: true }}
          multiline
          fullWidth
          style={{paddingRight:'15px'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdAlternateEmail style={{marginLeft:'5px'}}/>
              </InputAdornment>
            ),
          }}
          onChange={(e)=>setEmail(e.target.value)}
          defaultValue={email}
          error={emailerror}
          helperText={emailerror?'Invalid Email':null}
        />
        </div>
        <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
            <DateTimePicker
              label="Date and Time"
              renderInput={(params) => <TextField {...params} />}
              value={valuetime}
              onChange={(newValue) => {
              setValue(newValue);
              }}
            />
            </Stack>
            </LocalizationProvider>
        </div>
        <div>
        <TextField
          id="outlined-multiline-static"
          label="Description"
          placeholder='Describe salient aspects related to your appointment'
          InputLabelProps={{ shrink: true }}
          multiline
          rows={2}
          fullWidth
          style={{paddingRight:'15px'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MdOutlineDescription style={{marginLeft:'5px',marginBottom:'25px'}}/>
              </InputAdornment>
            ),
          }}
          defaultValue={description}
          onChange={(e)=>setDescription(e.target.value)}
          error={descriptionerror}
          helperText={descriptionerror?'Description cannot be empty':null}
        />
      
      </div>
      <div style={{marginLeft:'9px',marginBottom:'9px'}}>
          <Button startIcon={<AttachFileIcon/>}
          component="label" variant='outlined' style={{color:'rgba(0, 0, 0, 0.57)',border:'1px solid rgba(0, 0, 0, 0.24)'}}>
          Attach Media
          <input hidden accept="image/*" multiple type="file" />
          </Button>
      </div>
      </Stack>
      
      <div>
      <Button variant="contained" style={{margin:'20px',width: "150px",height: "40px"}}
      onClick={(e)=>validateinputs(e)}
      >
        <Typography fontSize='15px'>Submit</Typography>
      </Button>
      </div>
    </div>
    </div>
    </Box>

          
          </>)}

    
    </>
  )
}

export default Bookingform
import React from 'react'
import Slider from './Carousel';
import doctorimage from './Images/DrOD.webp'
import grad from './Images/grad.webp'

function Team() {
  

  const static_team =[
    {img: doctorimage,
      toptext:'Dr. Odeghe Daniel',
      headertext:'CEO',
      newtext:`Dr. O as he is fondly called by his patients, 
      is a Nigerian-American Dentist. He graduated from Howard 
      university college of dentistry and is also a member of the Greater 
      Cleveland Dental Society, The Ohio Dental Association, and the American Dental Association.`},
    {img: grad,
    toptext:'Graduation Ceremony',
    headertext:'Officially Dr O',
    newtext:'A picture which shows the start for Dr O and his wonderful career that followed'}
  ]

  
  return (
    <>
    <div className="w3-container" style={{padding:"270px 16px"}} id="team">
     <div className='w3-border-bottom w3-border-gray' style={{opacity:"20%"}}></div>
    <h3 className="w3-padding-16"  >THE TEAM</h3>
    <div className='w3-border-bottom w3-border-gray' style={{opacity:"20%"}}></div>
  <div className="w3-border-bottom w3-border-grey" style={{opacity:"20%"}}/> 
  
   
    <Slider aboutimages={static_team}/>
  </div>
    </>
  )
}

export default Team
export default function NotFound() {
  return (
    <div>
      <div>
        <h1
          style={{
            textAlign: "center",
            padding: "25%",
            font: "message-box",
            fontSize: "3rem",
          }}
        >
          404 Error<br></br>
          <br></br>Page Not Found
        </h1>
      </div>
    </div>
  );
}

import { React, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaArrowUp } from "react-icons/fa";


const BackToTop = () => {

  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const listenToScroll = () => {
    let heightHidden = 250;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightHidden) {
      setIsVisible(true);
    }
    else {
      setIsVisible(false)
    }
  };

  useEffect(() => {
    
    document.body.addEventListener('scroll', listenToScroll);
    return()=>document.body.removeEventListener('scroll',listenToScroll);
  }, []);
  return (
    <>
      <Wrapper >

        {isVisible && 
        (<div className='topButton' onClick={goToBtn}>
          <FaArrowUp className="top-btn--icon" />
        </div>)}

      </Wrapper>

    </>
  )
}

const Wrapper = styled.section`
display:flex;
justify-content:center;
align-items:center;

@media screen and (max-width: 369px) {

  .topButton{
    right: 0.2rem !;
  }
}

.topButton{
  font-size: 1.4rem;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: #07358A;
    border-radius: 50%;
    position: fixed;
    bottom: 2rem;
    right: 1.2rem;
  z-index:999;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;

  &--icon{
 animation:gototop 1,2s linear infinite alternate-reverse;
  }
  @keyframes gototop{
    0%{
      transform:translateY(-0.5rem);
    }
    100%{
      transform:translateY(1rem);
    }
  }

}

`;
export default BackToTop

// background-color:${({theme})=>theme.colors.btn};
  // box-shadow:${({theme})=>theme.colors.shadow};